<template>
  <div>
    <span v-if="orderList.length == 0" class="noDoctor">暂无订单</span>
    <div v-else>
      <van-card
        v-for="item in orderList"
        :key="item.id"
        :desc="' x ' + item.number + '副'"
        title=""
        @click="handleRecordDetail(item)"
      >
        <template #price> 就诊人：{{ item.name }} </template>
        <template #num>
          <span style="color: #b1080c">￥{{ item.amount.toFixed(2) }}</span>
        </template>
        <template #title>
          <div class="order-card__title">
            <span>新冠预防中药</span>
            <span>￥8.00 / 副</span>
          </div>
        </template>
        <template #thumb>
          <img src="../../assets/img/image2.png" alt="" />
        </template>
        <template #tags>
          <van-tag v-if="item.changeStatus >= 7">交易关闭</van-tag>
          <van-tag plain type="danger" v-else>{{
            changeStatus[item.changeStatus]
          }}</van-tag>
        </template>
        <template #footer>
          <div class="order-card-timeTips">
            {{ item.reason }}
          </div>
          <!-- <van-button
            size="small"
            v-if="item.changeStatus == 0"
            @click.stop="handleCancleOrder(item)"
            >取消订单</van-button
          > -->
          <van-button
            size="small"
            type="info"
            v-if="item.changeStatus == 0"
            @click.stop="handlePay(item)"
            >继续付款</van-button
          >
          <van-button
            size="small"
            type="danger"
            v-if="item.changeStatus == 3"
            @click.stop="handleRefund(item)"
            >退款</van-button
          >
          <van-button
            size="small"
            v-if="item.changeStatus >= 7"
            @click.stop="handleDeleteOrder(item)"
            >删除订单</van-button
          >
        </template>
      </van-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "traditionalMedicalRecord",
  data() {
    return {
      moment: require("moment"),
      orderList: [],
      changeStatus: {
        0: "待支付",
        1: "支付中",
        2: "异常",
        3: "待核对",
        4: "已自提",
        5: "待审核",
        6: "退款中",
        7: "用户取消",
        8: "医院取消",
        9: "自动取消",
        10: "已退款",
        11: "拒绝退款",
      },
      appId: "",
      timestamp: "",
      nonce: "",
      packageName: "",
      signType: "",
      signature: "",
      timer: null,
    };
  },
  methods: {
    handleRecordDetail(item) {
      this.$router.push({
        path: "/traditionalMedicalRecordDetail",
        query: {
          id: item.id,
        },
      });
    },
    getDataList() {
      let param = {
        openid: sessionStorage.getItem("openid"),
      };
      this.$http.get("/api/medicine/paymentList", param).then((result) => {
        this.orderList = result.data;
      });
    },
    /**
     * 取消订单
     */
    handleCancleOrder(item) {
      this.$dialog
        .confirm({
          message: "确定取消订单吗?",
          confirmButtonColor: "#00D48A",
        })
        .then(() => {
          this.$http.put("/api/medicine", { id: item.id }).then((result) => {
            this.$toast("已取消订单");
            this.getDataList();
          });
        });
    },
    /**
     * 退款
     */
    handleRefund(item) {
      this.$dialog
        .confirm({
          message: "确定退款吗?",
          confirmButtonColor: "#00D48A",
        })
        .then(() => {
          this.$http
            .get("/api/medicine/refund", { id: item.id })
            .then((result) => {
              this.$toast("已申请退款");
              this.getDataList();
            });
        });
    },
    /**
     * 删除订单
     * @param {*} item
     */
    handleDeleteOrder(item) {
      this.$dialog
        .confirm({
          message: "确定删除订单吗?",
          confirmButtonColor: "#00D48A",
        })
        .then(() => {
          this.$http
            .get("/api/medicine/del", { id: item.id })
            .then((result) => {
              this.$toast("已删除订单");
              this.getDataList();
            });
        });
    },
    /**
     * 继续付款
     */
    handlePay(item) {
      let postData = {
        id: item.id,
      };
      this.$http.get("/api/medicine/jsapi", postData).then((result) => {
        this.appId = result.data.appId;
        this.timestamp = result.data.timeStamp;
        this.nonce = result.data.nonceStr;
        this.packageName = result.data.package;
        this.signType = result.data.signType;
        this.signature = result.data.paySign;
        this.isWeChat();
      });
    },
    isWeChat() {
      let self = this;
      if (typeof WeixinJSBridge == "undefined") {
        self.onBridgeReady();
        Toast({ message: "请在微信客户端进行支付操作" });
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            self.onBridgeReady(),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", self.onBridgeReady());
          document.attachEvent("onWeixinJSBridgeReady", self.onBridgeReady());
        }
      } else {
        self.onBridgeReady();
      }
    },
    onBridgeReady() {
      let self = this;
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: self.appId, //公众号名称，由商户传入
          timeStamp: self.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: self.nonce, //随机串
          package: self.packageName,
          signType: self.signType, //微信签名方式：
          paySign: self.signature, //微信签名
        },
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            self.getDataList();
          }
        }
      );
    },
  },
  mounted() {
    this.getDataList();
    this.timer = window.setInterval(() => {
      setTimeout(() => {
        this.getDataList();
      }, 0);
    }, 10 * 1000);
  },
  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>

<style scoped>
.van-card {
  text-align: left;
  background-color: #fff;
  margin: 10px 0;
  font-size: 0.4rem;
}
.noDoctor {
  font-size: 0.4rem;
  margin-top: 0.3rem;
  display: block;
}
.order-card__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-card-timeTips {
  font-size: 0.35rem;
  margin: 0.2rem 0;
}
.van-card__price {
  font-size: 0.35rem;
}
</style>
